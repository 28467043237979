<template>
<div>

  <div style="padding:10px 50px;">
    <h3 style="text-align:left; color:#696969;">{{ $store.state.common.sScreenTitle }}</h3>
    <hr/>
  </div>

  <div v-show="$store.state.common.sIsLoading">
    Loading...
  </div>
  <div v-show="!$store.state.common.sIsLoading">

    <div style="padding:10px;">
      <div style="width:100%; text-align:right; margin-bottom:10px;">
        <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(0)">
          再表示
        </button>
        <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(1)" :style="vUpdateMode == 1 ? nowUpdateMode: ''">
          追加
        </button>
        <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(2)" :style="vUpdateMode == 2 ? nowUpdateMode: ''">
          変更
        </button>
        <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(3)" :style="vUpdateMode == 3 ? nowUpdateMode: ''">
          削除
        </button>
      </div>
    </div>

    <!-- データ一覧 -->
    <div style="padding:10px;">
      <div style="width:100%; text-align:center; margin-bottom:10px; height:200px; overflow:scroll;">
        <table class="sc-table" border="1">
          <thead>
            <th>行№</th>
            <th>{{ $store.state.sc_config.sLblScAnswerStartDate }}</th>
            <th>{{ $store.state.sc_config.sLblScAnswerEndDate }}</th>
            <th>{{ $store.state.sc_config.sLblScGroupAnalysis }}</th>
            <th>{{ $store.state.sc_config.sLblScConfAnswer }}</th>
            <th>{{ $store.state.sc_config.sLblScConfNotice }}</th>
            <th>{{ $store.state.sc_config.sLblScStatus }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="tDat,idx in vTblListData" :key="tDat.sc_id" :id="'cus-scconf-' + tDat.sc_id" @click="doStorageRowInfo(tDat.sc_id)">
              <td>{{ idx + 1 }}</td>
              <td>{{ tDat.sc_answer_start_date_disp }}</td>
              <td>{{ tDat.sc_answer_end_date_disp }}</td>
              <td>{{ tDat.sc_group_analysis_name }}</td>
              <td>{{ tDat.sc_conf_answer_name }}</td>
              <td>{{ tDat.sc_conf_notice_name }}</td>
              <td>{{ tDat.sc_status_name }}</td>
              <td>
                <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:100%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click.stop="doOpenScRequestModal(tDat.sc_id)">詳細</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr/>

    <!-- 入力エリア -->
    <div v-show="vUpdateMode == 1 || (vSelectedRow && (vUpdateMode == 2 || vUpdateMode == 3))" class="uk-flex uk-flex-center uk-margin">
      <div class="uk-container uk-width-1-1">
        <div class="uk-text-center" uk-grid>
          <div class="uk-width-1-1" style="text-align:left;">
            <h4 style="color:#696969;">入力欄</h4>
          </div>
          <div class="uk-width-1-3">
            <div class="uk-margin">
              <div style="text-align:left">
                <label class="uk-form-label uk-text-muted">{{ $store.state.sc_config.sLblScAnswerStartDate }}</label>
                <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
              </div>
              <input class="uk-input" type="date" v-model="vScAnswerStartDate" :disabled="vUpdateMode == 3">
            </div>
          </div>
          <div class="uk-width-1-3">
            <div class="uk-margin">
              <div style="text-align:left">
                <label class="uk-form-label uk-text-muted">{{ $store.state.sc_config.sLblScAnswerEndDate }}</label>
                <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
              </div>
              <input class="uk-input" type="date" v-model="vScAnswerEndDate" :disabled="vUpdateMode == 3">
            </div>
          </div>
          <div class="uk-width-1-3">
          </div>

          <div class="uk-width-1-3">
            <div class="uk-margin">
              <div style="text-align:left">
                <label class="uk-form-label uk-text-muted">{{ $store.state.sc_config.sLblScGroupAnalysis }}</label>
              </div>
              <select class="uk-select" v-model="vScGroupAnalysis" id="vScGroupAnalysis" @change="doSetSelectedLabel('vScGroupAnalysis')" :disabled="vUpdateMode == 3">
                <option v-for="nDat4 in vNameListData4" :value="nDat4.detail_name_id" :key="nDat4.detail_name_id">{{ nDat4.detail_display_name1 }}</option>
              </select>
            </div>
          </div>
          <div class="uk-width-1-3">
          </div>
          <div class="uk-width-1-3">
          </div>

          <!-- 分析グループエリア -->
          <div v-show="vScGroupAnalysis == 1" style="width:100%;">
            <div style="width:100%; text-align:left">
              <label style="color:#a9a9a9;"><small>{{ $store.state.sc_config.sLblWorkGroup }}</small></label>
            </div>
            <div style="width:100%; text-align:center;">
              <div style="height:400px; padding:5px; box-sizing:border-box; border-radius:10px; background-color:#d3d3d3;">
                <div style="width:45%; height:400px; float:left;">
                  <div style="display:inline-block; width:45%; padding:5px; box-sizing:border-box; background-color:#f5f5f5; border:solid 1px #696969; border-radius:10px; height:350px; overflow:scroll;">
                    <div style="display:inline-block; width:100%;">
                      <span style="color:#a9a9a9;">職場情報</span>
                    </div>
                    <div style="display:inline-block; width:100%;">
                      <ul style="list-style-type:none;">
                        <li v-for="tWp in vWpListDataForDisplay" :key="tWp.wp_id">
                          <button type="button" class="operate-button" style="width:50%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doMoveToRightList(tWp.wp_id)">{{ tWp.wp_name }}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div style="display:inline-block; width:45%; padding:5px; box-sizing:border-box; background-color:#f5f5f5; border:solid 1px #696969; border-radius:10px; height:350px; overflow:scroll;">
                    <div style="display:inline-block; width:100%;">
                      <span style="color:#a9a9a9;">グループ候補</span>
                    </div>
                    <div style="display:inline-block; width:100%;">
                      <ul style="list-style-type:none;">
                        <li v-for="tWp in vWpListDataCandidateGroup" :key="tWp.wp_id">
                          <button type="button" class="operate-button" style="width:50%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doMoveToLeftList(tWp.wp_id)">{{ tWp.wp_name }}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style="width:10%; height:350px; line-height:350px; float:left; vertical-align:middle;">
                  <div style="display:inline-block; width:100%;">
                    <button type="button" class="operate-button" style="width:50%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doCreateAnalysisGroup">
                      <b>＞</b>
                    </button>
                  </div>
                </div>
                <div style="width:45%; height:400px; float:left;">
                  <div style="display:inline-block; width:100%; padding:5px; box-sizing:border-box; background-color:#f5f5f5; border:solid 1px #696969; border-radius:10px; height:350px; overflow:scroll;">
                    <div style="display:inline-block; width:100%;">
                      <span style="color:#a9a9a9;">グループ</span>
                    </div>
                    <div style="display:inline-block; width:100%;">
                      <ul v-for="tGrp,idx in vAnalysisGroupList" :key="idx" style="width:40%; display:inline-block; list-style-type:none;">
                        <div style="background-color:#ffffff; border:solid 1px #a9a9a9; border-radius:10px;">
                          <div>
                            <b style="color:#696969;">＜グループ{{ idx + 1 }}＞</b>
                            <span class="uk-button-secondary" style="border-radius:20px;" uk-icon="close" @click="doReleaseAnalysisGroup(idx)"></span>
                          </div>
                          <li v-for="tGrpWp in tGrp" :key="tGrpWp.wp_id" style="border-radius:10px; color:#696969;">
                            ・{{ tGrpWp.wp_name }}
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-3">
            <div class="uk-margin">
              <div style="text-align:left">
                <label class="uk-form-label uk-text-muted">{{ $store.state.sc_config.sLblScConfAnswer }}</label>
              </div>
              <select class="uk-select" v-model="vScConfAnswer" id="vScConfAnswer" @change="doSetSelectedLabel('vScConfAnswer')" :disabled="vUpdateMode == 3">
                <option v-for="nDat5 in vNameListData5" :value="nDat5.detail_name_id" :key="nDat5.detail_name_id">{{ nDat5.detail_display_name1 }}</option>
              </select>
            </div>
          </div>

          <div class="uk-width-1-3">
            <div class="uk-margin">
              <div style="text-align:left">
                <label class="uk-form-label uk-text-muted">{{ $store.state.sc_config.sLblScConfNotice }}</label>
              </div>
              <select class="uk-select" v-model="vScConfNotice" id="vScConfNotice" @change="doSetSelectedLabel('vScConfNotice')" :disabled="vUpdateMode == 3">
                <option v-for="nDat6 in vNameListData6" :value="nDat6.detail_name_id" :key="nDat6.detail_name_id">{{ nDat6.detail_display_name1 }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding:10px;">
      <div style="width:100%; text-align:right; margin-bottom:10px;">
        <button v-show="vUpdateMode == 1 || (vSelectedRow && vUpdateMode == 2)" type="button" class="operate-button" style="width:10%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doConfirmUpdate(vModalId)">
          更新
        </button>
        <button v-show="vUpdateMode == 3 && vSelectedRow" type="button" class="operate-button" style="width:10%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doConfirmUpdate(vModalId)">
          削除
        </button>
      </div>
    </div>

    <!-- 何かメッセージ出す用 -->
    <p><b>{{ vMessage }}</b></p>

    <!-- 更新時モーダル -->
    <div :id="vModalId" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h3 class="uk-modal-title" style="color:#696969;">確認</h3>
        </div>
        <div class="uk-modal-body" uk-overflow-auto>
          <p style="color:#696969;">{{ $store.state.common.sUpdateModalMessage }}</p>
          <div style="padding:10px;">
            <div style="width:100%; text-align:center; margin-bottom:10px;">
              <table class="sc-table" border="1">
                <tr>
                  <th class="sc-th">{{ $store.state.sc_config.sLblScAnswerStartDate }}</th>
                  <td>{{ vScAnswerStartDateDisp }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.sc_config.sLblScAnswerEndDate }}</th>
                  <td>{{ vScAnswerEndDateDisp }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.sc_config.sLblScGroupAnalysis }}</th>
                  <td>{{ vScGroupAnalysisName }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.sc_config.sLblScConfAnswer }}</th>
                  <td>{{ vScConfAnswerName }}</td>
                </tr>
                <tr>
                  <th class="sc-th">{{ $store.state.sc_config.sLblScConfNotice }}</th>
                  <td>{{ vScConfNoticeName }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <div style="padding:10px;">
            <div style="width:100%; text-align:right; margin-bottom:10px; display:flex; justify-content:space-between;">
              <button type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doHideModal(vModalId)">
                キャンセル
              </button>
              <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doUpdateInfo">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ストレスチェック依頼モーダル -->
    <div :id="vModalIdScReq" uk-modal>
      <div class="uk-modal-dialog uk-width-auto">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <div style="padding:10px; box-sizing:border-box;">
            <div style="width:100%; text-align:center; font-size:1.5em; color:#696969;">
              ストレスチェック実施内容
            </div>
          </div>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
          <!-- <p>{{ $store.state.common.sUpdateModalMessage }}</p> -->

          <div style="padding:10px; box-sizing:border-box;">
            <div style="width:100%; text-align:center;">
              <div style="display:inline-block; width:35%; font-size:1.2em; background-color:#696969; border-radius:10px 0 0 0; padding:10px; box-sizing:border-box; color:#ffffff;">
                <b>実施対象者：{{ vScTargetCount }}人</b>
              </div>
              <div style="display:inline-block; width:35%; font-size:1.2em; background-color:#696969; border-radius:0 10px 0 0; padding:10px; box-sizing:border-box; color:#ffffff;">
                <b>暫定金額：{{ vScAmount.toLocaleString() }}円</b>
              </div>
            </div>
            <div style="width:100%; text-align:center;">
              <div style="display:inline-block; width:70%; text-align:center; padding:10px; box-sizing:border-box; border:solid 1px #696969; border-bottom:none; background-color:#a9a9a9; color:#696969;">
                ＜内訳＞
              </div>
            </div>
            <div style="width:100%; text-align:center;">
              <div style="display:inline-block; width:70%; text-align:center; box-sizing:border-box; border:solid 1px #696969; vertical-align:top;">
                <div style="display:inline-block; width:100%; padding:5px; box-sizing:border-box; background-color:#dcdcdc; color:#696969; float:left; vertical-align:top; border-bottom:dashed 1px #696969;">
                  回答形式
                </div>
                <div v-for="nDat5 in vNameListData5ex" :key="nDat5.detail_name_id" style="display:inline-block; width:100%; box-sizing:border-box; background-color:#dcdcdc; color:#696969; vertical-align:top; border-bottom:dashed 1px #696969;">
                  <div style="display:inline-block; width:25%; box-sizing:border-box color:#696969; vertical-align:top;">
                    <small>{{ nDat5.detail_display_name1 }}</small>
                  </div>
                  <div style="display:inline-block; width:25%; box-sizing:border-box; background-color:#ffffff; color:#696969; border-left:solid 1px #696969;">
                    <p v-if="nDat5.detail_name_id == 1"><small>{{ vScAnswerPaperCount.toLocaleString() }}人</small></p>
                    <p v-if="nDat5.detail_name_id == 2"><small>{{ vScAnswerWebCount.toLocaleString() }}人</small></p>
                  </div>
                  <div style="display:inline-block; width:50%; box-sizing:border-box; background-color:#ffffff; color:#696969; border-left:solid 1px #696969;">
                    <p v-if="nDat5.detail_name_id == 1"><small>{{ vScAnswerPaperPrice.toLocaleString() }}円</small></p>
                    <p v-if="nDat5.detail_name_id == 2"><small>{{ vScAnswerWebPrice.toLocaleString() }}円</small></p>
                  </div>
                </div>
              </div>
            </div>

            <div style="width:100%; text-align:center;">
              <div style="display:inline-block; width:70%; text-align:center; box-sizing:border-box; border:solid 1px #696969; border-top:none; vertical-align:top;">
                <div style="display:inline-block; width:100%; padding:5px; box-sizing:border-box; background-color:#dcdcdc; color:#696969; float:left; vertical-align:top; border-bottom:dashed 1px #696969;">
                  結果通知形式
                </div>
                <div v-for="nDat6 in vNameListData6ex" :key="nDat6.detail_name_id" style="display:inline-block; width:100%; box-sizing:border-box; background-color:#dcdcdc; color:#696969; vertical-align:top; border-bottom:dashed 1px #696969;">
                  <div style="display:inline-block; width:25%; box-sizing:border-box color:#696969; vertical-align:top;">
                    <small>{{ nDat6.detail_display_name1 }}</small>
                  </div>
                  <div style="display:inline-block; width:25%; box-sizing:border-box; background-color:#ffffff; color:#696969; border-left:solid 1px #696969;">
                    <p v-if="nDat6.detail_name_id == 1"><small>{{ vScNoticePaperCount.toLocaleString() }}人</small></p>
                    <p v-if="nDat6.detail_name_id == 2"><small>{{ vScNoticeWebCount.toLocaleString() }}人</small></p>
                  </div>
                  <div style="display:inline-block; width:50%; box-sizing:border-box; background-color:#ffffff; color:#696969; border-left:solid 1px #696969;">
                    <p v-if="nDat6.detail_name_id == 1"><small>{{ vScNoticePaperPrice.toLocaleString() }}円</small></p>
                    <p v-if="nDat6.detail_name_id == 2"><small>{{ vScNoticeWebPrice.toLocaleString() }}円</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="padding:10px;">
            <div style="width:100%; text-align:center; margin-bottom:10px; height:300px; overflow:scroll;">
              <table class="sc-table" border="1" style="width:100%;">
                <thead>
                  <th>
                    実施
                    <input type="checkbox" v-model="vDoScTargetCheckAll" @change="doChangeAllTarget()">
                  </th>
                  <th>行№</th>
                  <th>{{ $store.state.employee.sLblEmpCode }}</th>
                  <th>{{ $store.state.employee.sLblEmpName }}</th>
                  <th>{{ $store.state.employee.sLblEmpKana }}</th>
                  <th>{{ $store.state.employee.sLblEmpWpId }}</th>
                  <th>{{ $store.state.employee.sLblEmpBirthday }}</th>
                  <th>{{ $store.state.employee.sLblEmpSex }}</th>
                  <th>{{ $store.state.employee.sLblEmpMailAddress }}</th>
                  <th v-show="vScConfAnswer == 3">
                    {{ $store.state.sc_config.sLblScConfAnswer }}
                  </th>
                  <th v-show="vScConfNotice == 3">
                    {{ $store.state.sc_config.sLblScConfNotice }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="tDat,idx in vScTargetListData" :key="tDat.emp_code">
                    <td>
                      <input type="checkbox" v-model="vScTargetListData[idx].do_sc">
                    </td>
                    <td>{{ idx + 1 }}</td>
                    <td>{{ tDat.emp_code }}</td>
                    <td>{{ tDat.emp_name }}</td>
                    <td>{{ tDat.emp_kana }}</td>
                    <td>{{ tDat.wp_name }}</td>
                    <td>{{ tDat.emp_birthday_disp }}</td>
                    <td>{{ tDat.emp_sex_name }}</td>
                    <td>{{ tDat.emp_mail_address }}</td>
                    <td v-show="vScConfAnswer == 3">
                      <div v-for="nDat5 in vNameListData5ex" :key="nDat5.detail_name_id">
                        <label>
                          <input type="radio" :name="'scAnswer' + idx" :value="nDat5.detail_name_id" v-model="vScTargetListData[idx].sc_conf_answer">
                          {{ nDat5.detail_display_name1 }}
                        </label>
                      </div>
                    </td>
                    <td v-show="vScConfNotice == 3">
                      <div v-for="nDat6 in vNameListData6ex" :key="nDat6.detail_name_id">
                        <label>
                          <input type="radio" :name="'scNotice' + idx" :value="nDat6.detail_name_id" v-model="vScTargetListData[idx].sc_conf_notice">
                          {{ nDat6.detail_display_name1 }}
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <div style="padding:10px;">
            <div style="width:100%; text-align:right; margin-bottom:10px; display:flex; justify-content:space-between;">
              <button type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doHideModal(vModalIdScReq)">
                閉じる
              </button>
              <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doConfirmUpdateScRequest">
                依頼する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

</div>
  </div>

</template>

<script>
  import constant from '@/constant.js'
  import UIkit from 'uikit'

  export default {
    // components: {
    //   Modal
    // },
    data() {
      return {
        // showContent: false,
        // postData: [],
        vComId: this.$store.state.common.sComId,
        vTblListData: [],
        vWpListData: [],  // 職場情報
        vAgListData: [],  // 分析グループデータ
        vNameListData4: [], // 集団分析
        vNameListData5: [], // 回答形式
        vNameListData6: [], // 結果通知形式
        vNameListData8: [], // 実施状態
        vNameListData5ex: [], // 回答形式（抽出）
        vNameListData6ex: [], // 結果通知形式（抽出）
        vScTargetListData: [],  // ストレスチェック依頼対象
        vUpdateMode: constant.cPageOperationMode.nothing,
        vSelectedRow: false,
        vModalId: 'modal-confirm-customer-sc-conf',
        vModalIdScReq: 'modal-confirm-customer-sc-request',
        vScId: '',
        vScAnswerStartDate: '',
        vScAnswerStartDateDisp: '',
        vScAnswerEndDate: '',
        vScAnswerEndDateDisp: '',
        vScGroupAnalysis: '',
        vScGroupAnalysisName: '',
        vScConfAnswer: '',
        vScConfAnswerName: '',
        vScConfNotice: '',
        vScConfNoticeName: '',
        vMessage: '',
        'nowUpdateMode': {
          'background-color': '#696969',
          'color': '#ffffff',
          'font-weight': 'bold'
        },
        vDoScTargetCheckAll: false, // 実施対象全選択checkboxモデル
        vWpListDataForDisplay: [],  // 職場情報（分析グループ作成用）
        vWpListDataCandidateGroup: [],  // 職場分析グループ一時保持用
        vAnalysisGroupList: [], // 分析グループリスト
      }
    },
    created() {
      // 画面読み込み中・・・
      this.$store.state.common.sIsLoading = true;
      // ログイン状態判定
      this.doAuthLoginInfo()
      if (!this.$store.state.common.sIsLogined) {
        this.$router.push('/login')
      }
      // 画面タイトル
      this.$store.commit('common/doSetScreenTitle', {
        vScreenTitle: 'クライアント/ストレスチェック設定'
      });
      // DB参照処理
      if (this.vComId) {
        this.vMessage = '';
        this.$axios.get(constant.cServerRoute.customer.sc_config, {
          params: {
            pComId: this.vComId
          }
        })
          .then(function(response){
            console.log(response);
            // 実施管理データ参照処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 実施管理データを保持する
              if (response.data.return_data_sc) {
                this.vTblListData = response.data.return_data_sc;
              }
              // 部署情報を保持する
              if (response.data.return_data_wp) {
                this.vWpListData = response.data.return_data_wp;
              }
              // 分析グループデータを保持する
              if (response.data.return_data_ag) {
                this.vAgListData = response.data.return_data_ag;
              }
              // 表示名情報を保持する（集団分析）
              if (response.data.return_data_name4) {
                this.vNameListData4 = response.data.return_data_name4;
              }
              // 表示名情報を保持する（回答形式）
              if (response.data.return_data_name5) {
                this.vNameListData5 = response.data.return_data_name5;
              }
              // 表示名情報を保持する（結果通知形式）
              if (response.data.return_data_name6) {
                this.vNameListData6 = response.data.return_data_name6;
              }
              // 表示名情報を保持する（実施状態）
              if (response.data.return_data_name8) {
                this.vNameListData8 = response.data.return_data_name8;
              }
              // 表示名情報を保持する（回答形式/抽出）
              if (response.data.return_data_name5ex) {
                this.vNameListData5ex = response.data.return_data_name5ex;
              }
              // 表示名情報を保持する（結果通知形式/抽出）
              if (response.data.return_data_name6ex) {
                this.vNameListData6ex = response.data.return_data_name6ex;
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
          })
      }
    },
    // 画面読み込み終わった？
    mounted() {
      this.$store.state.common.sIsLoading = false;
    },
    computed: {
      // 全対象者数
      vScTargetCount: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.all);
        }
      },
      // マークシート回答者数
      vScAnswerPaperCount: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.paper, constant.cScConfForCalcText.answer);
        }
      },
      // Web回答者数
      vScAnswerWebCount: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.web, constant.cScConfForCalcText.answer);
        }
      },
      // ストレスシート通知数
      vScNoticePaperCount: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.paper, constant.cScConfForCalcText.notice);
        }
      },
      // Web通知数
      vScNoticeWebCount: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.web, constant.cScConfForCalcText.notice);
        }
      },
      vScAmount: {
        get() {
          return this.vScAnswerPaperPrice + this.vScAnswerWebPrice + this.vScNoticePaperPrice + this.vScNoticeWebPrice;
        }
      },
      // マークシート回答単価
      vScAnswerPaperPrice: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.paper, constant.cScConfForCalcText.answer) * this.doGetUnitPrice(constant.cScConfForCalc.paper, constant.cScConfForCalcText.answer);
        }
      },
      // Web回答単価
      vScAnswerWebPrice: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.web, constant.cScConfForCalcText.answer) * this.doGetUnitPrice(constant.cScConfForCalc.web, constant.cScConfForCalcText.answer);
        }
      },
      // ストレスシート通知単価
      vScNoticePaperPrice: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.paper, constant.cScConfForCalcText.notice) * this.doGetUnitPrice(constant.cScConfForCalc.paper, constant.cScConfForCalcText.notice);
        }
      },
      // Web通知単価
      vScNoticeWebPrice: {
        get() {
          return this.doGetScTargetCount(constant.cScConfForCalc.web, constant.cScConfForCalcText.notice) * this.doGetUnitPrice(constant.cScConfForCalc.web, constant.cScConfForCalcText.notice);
        }
      },
    },
    methods: {
      // DB更新処理
      doUpdateInfo() {
        this.vMessage = '';
        // 入力チェック
        this.$store.state.common.sAbleUpdate = true;
        this.doCheckValueBeforeUpdate();
        if (!this.$store.state.common.sAbleUpdate) {
          // モーダル閉じる
          this.doHideModal(this.vModalId);
          return;
        }
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        // DB更新
        this.$axios.post(constant.cServerRoute.customer.sc_config, {
          pUpdateMode: this.vUpdateMode,
          pScId: this.vScId,
          pComId: this.vComId,
          pScGroupAnalysis: this.vScGroupAnalysis,
          pScAnswerStartDate: this.vScAnswerStartDate,
          pScAnswerEndDate: this.vScAnswerEndDate,
          pScConfAnswer: this.vScConfAnswer,
          pScConfNotice: this.vScConfNotice,
          pAnalysisGroupList: this.vAnalysisGroupList
        })
          .then(function(response){
            console.log(response);
            // 実施管理データ更新処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              this.vTblListData = response.data.return_data_sc;
              // 部署情報を保持する
              if (response.data.return_data_wp) {
                this.vWpListData = response.data.return_data_wp;
              }
              // 分析グループデータを保持する
              if (response.data.return_data_ag) {
                this.vAgListData = response.data.return_data_ag;
              }
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.success
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              // 値クリアしておく
              this.doClear();
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            } else {
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.failure
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            }
          }.bind(this))
          .catch(function(error){
            // 更新メッセージ表示
            this.$store.commit('common/doNotificationUpdate', {
              'vMode': constant.cNotificationUpdateMode.failure
            });
            // モーダル閉じる
            this.doHideModal(this.vModalId);
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // 更新前値チェック処理
      doCheckValueBeforeUpdate() {
        // アンケート回答開始年月日
        this.$store.commit('common/doCheckInput', {
          vVal: this.vScAnswerStartDate,
          vLabel: this.$store.state.sc_config.sLblScAnswerStartDate
        })
        // アンケート回答終了年月日
        this.$store.commit('common/doCheckInput', {
          vVal: this.vScAnswerEndDate,
          vLabel: this.$store.state.sc_config.sLblScAnswerEndDate
        })
        // 分析グループ（集団分析有りの場合）
        if (this.vScGroupAnalysis == constant.cGroupAnalysis.do) {
          this.$store.commit('common/doCheckObjectLength', {
            vVal: this.vAnalysisGroupList,
            vLabel: this.$store.state.sc_config.sLblWorkGroup
          })
        }

        // // 集団分析
        // this.$store.commit('common/doCheckSelect', {
        //   vVal: this.vScGroupAnalysis,
        //   vLabel: this.$store.state.sc_config.sLblScGroupAnalysis
        // })
        // // 回答形式
        // this.$store.commit('common/doCheckSelect', {
        //   vVal: this.vScConfAnswer,
        //   vLabel: this.$store.state.sc_config.sLblScConfAnswer
        // })
        // // 結果通知形式
        // this.$store.commit('common/doCheckSelect', {
        //   vVal: this.vScConfNotice,
        //   vLabel: this.$store.state.sc_config.sLblScConfNotice
        // })
      },
      // DB更新前確認処理
      doConfirmUpdate(modalId) {
        UIkit.modal('#' + modalId).toggle();
      },
      // モーダル閉じる
      doHideModal(modalId) {
        UIkit.modal('#' + modalId).hide();
      },
      // 入力エリア表示制御
      doSwitchInputArea(mode) {
        this.doClear();
        this.vUpdateMode = mode
        if (mode == constant.cPageOperationMode.add) {
          // selectオブジェクトのoptionを選択状態にしておく
          this.doSelectedOption('vScGroupAnalysis', mode);
          this.doSelectedOption('vScConfAnswer', mode);
          this.doSelectedOption('vScConfNotice', mode);
          // selectオブジェクトの表示ラベルを保持しておく
          this.doSetSelectedLabel('vScGroupAnalysis');
          this.doSetSelectedLabel('vScConfAnswer');
          this.doSetSelectedLabel('vScConfNotice');
        }
        // レコード操作モード表示名設定
        this.$store.commit('common/doSetUpdateModeDisplayName', {
          vUpdateMode: this.vUpdateMode
        })
        // 更新モーダル内メッセージ設定
        this.$store.commit('common/doSetUpdateModalMessage', {
          vUpdateMode: this.vUpdateMode
        })
      },
      // 選択行情報保持
      doStorageRowInfo(key1) {
        if (this.vUpdateMode == constant.cPageOperationMode.change || this.vUpdateMode == constant.cPageOperationMode.delete) {
          // this.vSelectRowIndex = rowIdx;
          // 行色制御
          this.doChangeRowColor(constant.cChangeRowColor.nothing);
          // 行情報保持
          this.doGetSelectedRowInfo(key1);
          // selectオブジェクトのoptionを選択状態にしておく
          this.doSelectedOption('vScGroupAnalysis');
          this.doSelectedOption('vScConfAnswer');
          this.doSelectedOption('vScConfNotice');
          // 行選択状態にする
          this.vSelectedRow = true;
          // selectオブジェクトの表示ラベルを保持しておく
          this.doSetSelectedLabel('vScGroupAnalysis');
          this.doSetSelectedLabel('vScConfAnswer');
          this.doSetSelectedLabel('vScConfNotice');
          // 選択行に色をつける
          this.doChangeRowColor(constant.cChangeRowColor.coloring);
        }
      },
      // 一覧の選択行情報を保持
      doGetSelectedRowInfo(key) {
        var i = 0;
        for (i = 0; i < this.vTblListData.length; i++) {
          if (this.vTblListData[i].sc_id == key) {
            this.vScId = key;
            this.vScAnswerStartDate = this.vTblListData[i].sc_answer_start_date;
            this.vScAnswerStartDateDisp = this.vTblListData[i].sc_answer_start_date_disp;
            this.vScAnswerEndDate = this.vTblListData[i].sc_answer_end_date;
            this.vScAnswerEndDateDisp = this.vTblListData[i].sc_answer_end_date_disp;
            this.vScGroupAnalysis = this.vTblListData[i].sc_group_analysis;
            this.vScGroupAnalysisName = this.vTblListData[i].sc_group_analysis_name;
            this.vScConfAnswer = this.vTblListData[i].sc_conf_answer;
            this.vScConfAnswerName = this.vTblListData[i].sc_conf_answer_name;
            this.vScConfNotice = this.vTblListData[i].sc_conf_notice;
            this.vScConfNoticeName = this.vTblListData[i].sc_conf_notice_name;
            this.doFilterAnalysisGroupForDisplay();
            break;
          }
        }
      },
      // 値をクリア
      doClear() {
        this.vUpdateMode = constant.cPageOperationMode.nothing;
        // this.vSelectRowIndex = -1;
        this.vSelectedRow = false;
        // 行色制御
        this.doChangeRowColor(constant.cChangeRowColor.nothing);
        // 選択行情報クリア
        this.vScGroupAnalysis = '';
        this.vScAnswerStartDate = '';
        this.vScAnswerStartDateDisp = '';
        this.vScAnswerEndDate = '';
        this.vScAnswerEndDateDisp = '';
        this.vConfAnswer = '';
        this.vConfAnswerName = '';
        this.vConfNotice = '';
        this.vConfNoticeName = '';
        this.vWpListDataForDisplay = this.vWpListData.slice();
        this.vWpListDataCandidateGroup = [];
        this.vAnalysisGroupList = [];
        this.vAnalysisGroupList = [];
        this.vScTargetListData = [];
        this.vDoScTargetCheckAll = false;
      },
      // 一覧行の色変更
      // mode:0（行の色を消す）、mode:1（選択行に色をつける）
      doChangeRowColor(mode) {
        var setColor = '';
        if (mode == constant.cChangeRowColor.coloring) {
          setColor = "#e0ffff";
        }
        var tmpInput1 = document.getElementById('cus-scconf-' + this.vScId);
        if (tmpInput1) {tmpInput1.style.backgroundColor = setColor}
      },
      // selectオブジェクトを選択状態にする
      doSelectedOption(objId, mode = 0) {
        var loopSelect = 0;
        var obj = document.getElementById(objId);
        var loopBreak = false;
        if (obj) {
          for (loopSelect = 0; loopSelect < obj.options.length; loopSelect++) {
            switch (objId) {
              case 'vScGroupAnalysis':
                // 集団分析
                if (mode == constant.cPageOperationMode.add) {
                  obj[0].selected = true;
                  this.vScGroupAnalysis = obj.options[obj.selectedIndex].value;
                  loopBreak = true;
                  break;
                } else if (obj.options[loopSelect].value == this.vScGroupAnalysis) {
                  obj[loopSelect].selected = true;
                  loopBreak = true;
                }
                break;
              case 'vScConfAnswer':
                // 回答形式
                if (mode == constant.cPageOperationMode.add) {
                  obj[1].selected = true;
                  this.vScConfAnswer = obj.options[obj.selectedIndex].value;
                  loopBreak = true;
                } else if (obj.options[loopSelect].value == this.vScConfAnswer) {
                  obj[loopSelect].selected = true;
                  loopBreak = true;
                }
                break;
              case 'vScConfNotice':
                // 結果通知形式
                if (mode == constant.cPageOperationMode.add) {
                  obj[1].selected = true;
                  this.vScConfNotice = obj.options[obj.selectedIndex].value;
                  loopBreak = true;
                } else if (obj.options[loopSelect].value == this.vScConfNotice) {
                  obj[loopSelect].selected = true;
                  loopBreak = true;
                }
                break;
            }
            if (loopBreak) {
              break;
            }
          }
        }
      },
      // selectオブジェクトの表示ラベルをセットする
      doSetSelectedLabel(objId) {
        var obj = document.getElementById(objId);
        if (obj) {
          if (obj.selectedIndex > -1) {
            var txt = obj.options[obj.selectedIndex].text;
            switch (objId) {
              case 'vScGroupAnalysis':
                // 職場名
                this.vScGroupAnalysisName = txt;
                break;
              case 'vScConfAnswer':
                // 性別
                this.vScConfAnswerName = txt;
                break;
              case 'vScConfNotice':
                // 性別
                this.vScConfNoticeName = txt;
                break;
            }
          }
        }
      },
      // 分析グループを表示用に整形
      doFilterAnalysisGroupForDisplay() {
        this.vAnalysisGroupList = [];
        this.vWpListDataForDisplay = this.vWpListData.slice();
        // 一覧で選択した行の実施IDで絞る
        var filterListData = this.vAgListData.filter(item => item.sc_id == this.vScId)
        var i = 0;
        var tmpAgCode = '';
        var tmpArray = [];
        if (filterListData.length > 0) {
          for (i = 0; i < filterListData.length; i++) {
            // 分析グループ番号ごとに部署をまとめる
            if ((tmpAgCode != '') && (filterListData[i].analysis_group_code != tmpAgCode)) {
              this.vAnalysisGroupList.push(tmpArray);
              tmpArray = [];
            }
            tmpArray.push(filterListData[i]);
            tmpAgCode = filterListData[i].analysis_group_code;
            // 部署情報リストから分析グループにある部署を除外する
            this.vWpListDataForDisplay = this.vWpListDataForDisplay.filter(item => item.wp_id != filterListData[i].wp_id)
          }
          if (tmpArray.length > 0) {
            this.vAnalysisGroupList.push(tmpArray);
          }
        }
      },
      // 分析グループ（右のリストへ反映）
      doMoveToRightList(wpId) {
        var i = 0;
        var tmpArray = [];
        for (i = 0; i < this.vWpListDataForDisplay.length; i++) {
          if (this.vWpListDataForDisplay[i].wp_id == wpId) {
            tmpArray = this.vWpListDataForDisplay[i];
            this.vWpListDataCandidateGroup = this.vWpListDataCandidateGroup.concat(tmpArray);
            this.vWpListDataForDisplay.splice(i, 1);
            break;
          }
        }
        this.vWpListDataForDisplay.sort((a,b)=> a.wp_id - b.wp_id);
        this.vWpListDataCandidateGroup.sort((a,b)=> a.wp_id - b.wp_id);
      },
      // 分析グループ（左のリストへ反映）
      doMoveToLeftList(wpId) {
        var i = 0;
        var tmpArray = [];
        for (i = 0; i < this.vWpListDataCandidateGroup.length; i++) {
          if (this.vWpListDataCandidateGroup[i].wp_id == wpId) {
            tmpArray = this.vWpListDataCandidateGroup[i];
            this.vWpListDataForDisplay = this.vWpListDataForDisplay.concat(tmpArray);
            this.vWpListDataCandidateGroup.splice(i, 1);
            break;
          }
        }
        this.vWpListDataForDisplay.sort((a,b)=> a.wp_id - b.wp_id);
        this.vWpListDataCandidateGroup.sort((a,b)=> a.wp_id - b.wp_id);
      },
      // グループ化
      doCreateAnalysisGroup() {
        if (this.vWpListDataCandidateGroup.length > 0) {
          this.vAnalysisGroupList.push(this.vWpListDataCandidateGroup);
          this.vWpListDataCandidateGroup = [];
        }
      },
      // グループ解除
      doReleaseAnalysisGroup(idx) {
        var tmpArray = [];
        tmpArray = this.vAnalysisGroupList[idx];
        this.vWpListDataForDisplay = this.vWpListDataForDisplay.concat(tmpArray);
        this.vWpListDataForDisplay.sort((a,b)=> a.wp_id - b.wp_id);
        this.vAnalysisGroupList.splice(idx, 1);
      },
      // ストレスチェック依頼モーダルopen
      doOpenScRequestModal(scId) {
        if (!this.vUpdateMode == constant.cPageOperationMode.change && !this.vUpdateMode == constant.cPageOperationMode.delete) {
          return;
        }
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.$axios.get(constant.cServerRoute.customer.sc_config_request, {
          params: {
            pComId: this.vComId,
            pScId: scId
          }
        })
          .then(function(response){
            console.log(response);
            // 社員情報参照処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 社員情報を保持する
              if (response.data.return_data_target) {
                this.vScTargetListData = response.data.return_data_target;
                // 実施管理データの情報を保持する
                this.doGetSelectedRowInfo(scId);
                // ストレスチェック依頼モーダル表示
                this.doConfirmUpdate(this.vModalIdScReq);
              }
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            } else {
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // 実施対象者選別（全）
      doChangeAllTarget() {
        // 実施対象の社員番号リストを一旦全削除する
        var i = 0;
        if (this.vDoScTargetCheckAll) {
          // 全選択の場合
          for (i = 0; i < this.vScTargetListData.length; i++) {
            this.vScTargetListData[i].do_sc = true;
          }
        } else {
          // 全解除の場合
          for (i = 0; i < this.vScTargetListData.length; i++) {
            this.vScTargetListData[i].do_sc = false;
          }
        }
      },
      // ストレスチェック依頼確定処理
      doConfirmUpdateScRequest() {
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.vUpdateMode = constant.cPageOperationMode.add;
        // DB更新
        this.$axios.post(constant.cServerRoute.customer.sc_config_request, {
          pUpdateMode: this.vUpdateMode,
          pScId: this.vScId,
          pComId: this.vComId,
          pScTargetList: this.vScTargetListData
        })
          .then(function(response){
            console.log(response);
            // 実施管理データ・回答データ更新処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              this.vTblListData = response.data.return_data_sc;
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.success
              });
              // モーダル閉じる
              this.doHideModal(this.vModalIdScReq);
              // 値クリアしておく
              this.doClear();
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            } else {
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.failure
              });
              // モーダル閉じる
              this.doHideModal(this.vModalIdScReq);
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            }
          }.bind(this))
          .catch(function(error){
            // 更新メッセージ表示
            this.$store.commit('common/doNotificationUpdate', {
              'vMode': constant.cNotificationUpdateMode.failure
            });
            // モーダル閉じる
            this.doHideModal(this.vModalIdScReq);
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // ストレスチェック実施対象者数を取得
      doGetScTargetCount(target, situation = '') {
        if (this.vScTargetListData.length == 0) {
          return this.vScTargetListData.length;
        }
        var tmpArray = this.vScTargetListData.filter((a)=> a.do_sc);

        // 全対象者数
        if (target == constant.cScConfForCalc.all) {
          return tmpArray.length;
        }
        // マークシート or Web
        switch (situation) {
          case constant.cScConfForCalcText.answer:
            tmpArray = tmpArray.filter((a)=> a.sc_conf_answer == target);
            break;
          case constant.cScConfForCalcText.notice:
            tmpArray = tmpArray.filter((a)=> a.sc_conf_notice == target);
            break;
        }
        return tmpArray.length;
      },
      // ストレスチェックの単価を取得
      doGetUnitPrice(target, situation) {
        var price = 0;

        switch (target) {
          // 紙
          case constant.cScConfForCalc.paper:
            switch (situation) {
              // 回答
              case constant.cScConfForCalcText.answer:
                price = 550;
                break;
              // 結果通知
              case constant.cScConfForCalcText.notice:
                price = 550;
                break;
            }
            break;
          // Web
          case constant.cScConfForCalc.web:
            switch (situation) {
              // 回答
              case constant.cScConfForCalcText.answer:
                price = 250;
                break;
              // 結果通知
              case constant.cScConfForCalcText.notice:
                price = 250;
                break;
            }
            break;
        }
        return price;
      },
      // 戻る処理
      doBackToHome() {
        this.$router.push('/customer-home');
      },
      // ID,パスワード照合
      doAuthLoginInfo() {
        if (sessionStorage.getItem('storageId')) {
          let createHash = require("sha256-uint8array").createHash;
          let tmp = createHash().update(sessionStorage.getItem('storagePw')).digest("hex");
          this.$axios.post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem('storageId'),
            pLoginPw: tmp
          })
          .then(function(response){
            console.log(response);
            if (response.data.return_result != constant.cServerReturnResult.success) {
              this.$store.state.common.sIsLogined = false;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          })
        }
      }
    },
    // ページを離れる
    beforeRouteLeave() {
      UIkit.modal('#' + this.vModalId).$destroy(true);
      UIkit.modal('#' + this.vModalIdScReq).$destroy(true);
    }
  }
</script>
